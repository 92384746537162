function setupGetStartedBlock() {
	const nextButton = document.getElementById('getStartedNextButton');
	const checkboxes = document.getElementById('getStartedCheckboxes');

	const updateNextButton = function (href, target, title) {
		nextButton.setAttribute('href', href);
		nextButton.setAttribute('target', target);
		nextButton.setAttribute('title', title);
	};

	checkboxes.addEventListener('click', function (event) {
		if (event.target.value === 'getStartedYesCheck') {
			const href = nextButton.getAttribute('data-yes-url');
			const target = nextButton.getAttribute('data-yes-target');
			const title = nextButton.getAttribute('data-yes-title');
			updateNextButton(href, target, title);
		} else if (event.target.value === 'getStartedNoCheck') {
			const href = nextButton.getAttribute('data-no-url');
			const target = nextButton.getAttribute('data-no-target');
			const title = nextButton.getAttribute('data-no-title');
			updateNextButton(href, target, title);
		}
	});
}

if (document.getElementsByClassName('block-get-started').length > 0) {
	setupGetStartedBlock();
}
