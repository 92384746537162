const fadeInStart = 'top 70%';
const fadeInToggle = 'restart none none reverse';
const fadeInDuration = 0.75;

function initScrollAnimations() {
	gsap.utils.toArray('.fade-in-up').forEach((fade) => {
		gsap.fromTo(
			fade,
			{
				autoAlpha: 0,
				y: 50,
			},
			{
				scrollTrigger: {
					trigger: fade,
					start: fadeInStart,
					toggleActions: fadeInToggle,
				},
				autoAlpha: 1,
				y: 0,
				duration: fadeInDuration,
			}
		);
	});

	gsap.utils.toArray('.fade-in-down').forEach((fade) => {
		gsap.fromTo(
			fade,
			{
				autoAlpha: 0,
				y: -50,
			},
			{
				scrollTrigger: {
					trigger: fade,
					start: fadeInStart,
					toggleActions: fadeInToggle,
				},
				autoAlpha: 1,
				y: 0,
				duration: fadeInDuration,
			}
		);
	});

	gsap.utils.toArray('.fade-in-left').forEach((fade) => {
		gsap.fromTo(
			fade,
			{
				autoAlpha: 0,
				x: 50,
			},
			{
				scrollTrigger: {
					trigger: fade,
					start: fadeInStart,
					toggleActions: fadeInToggle,
				},
				autoAlpha: 1,
				x: 0,
				duration: fadeInDuration,
			}
		);
	});

	gsap.utils.toArray('.fade-in-right').forEach((fade) => {
		gsap.fromTo(
			fade,
			{
				autoAlpha: 0,
				x: -50,
			},
			{
				scrollTrigger: {
					trigger: fade,
					start: fadeInStart,
					toggleActions: fadeInToggle,
				},
				autoAlpha: 1,
				x: 0,
				duration: fadeInDuration,
			}
		);
	});
}

function addScrollAnimations() {
	const ignore = [
		'fade-in-up',
		'fade-in-down',
		'fade-in-left',
		'fade-in-right',
		'wp-block-spacer',
	];

	$('.entry-content > *').each(function (i) {
		if (i === 0) return; // eslint-disable-line no-useless-return

		// Don't add animation if the first element is too short.
		if (i === 1) {
			const first = $('.entry-content > *:first-child');
			if (first.outerHeight() < $(window).height() * 0.7) {
				return;
			}
		}

		const $this = $(this);

		for (let classIndex = 0; classIndex < ignore.length; classIndex++) {
			if ($this.hasClass(ignore[classIndex])) {
				return; // eslint-disable-line no-useless-return
			}
		}

		// Core Columns
		if ($this.hasClass('wp-block-columns')) {
			$this.find('.wp-block-column > *').addClass('fade-in-up');
			return; // eslint-disable-line no-useless-return
		}

		// Core Media & Text
		if ($this.hasClass('wp-block-media-text')) {
			$this.find('.wp-block-media-text__content').addClass('fade-in-up');
			return; // eslint-disable-line no-useless-return
		}

		$(this).addClass('fade-in-up');
	});
}

$(document).ready(function () {
	initScrollAnimations();
	addScrollAnimations();
});
