// Bootstrap Imports
import 'popper.js';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/tab';
import './animations';

/*******************************************
 *  Helper Functions
 *******************************************/

// Handles flash of unstyled content.
function fouc() {
	$('html').removeClass('no-js').addClass('js');
}

// Optimize the scroll events for better browser performance.
//
// Broadcasts optimizedScroll event on the window.
// Use the same way you would add a window scroll listener.
// $(window).on('optimizedScroll', ...);
function optimizeScroll() {
	let scrollWaiting = false,
		endScrollHandle;
	$(window).scroll(function () {
		if (scrollWaiting) {
			return;
		}
		scrollWaiting = true;

		// clear previous scheduled endScrollHandle
		clearTimeout(endScrollHandle);

		$(window).trigger('optimizedScroll');

		setTimeout(function () {
			scrollWaiting = false;
		}, 100);

		// schedule an extra execution of scroll() after 200ms
		// in case the scrolling stops in next 100ms
		endScrollHandle = setTimeout(function () {
			$(window).trigger('optimizedScroll');
		}, 200);
	});
}

function mainNavigationScroll() {
	$(window).on('optimizedScroll', function () {
		const scrollTop = $(window).scrollTop();
		const $siteHeader = $('.site-header');
		const $siteHeaderSpacer = $('.site-header__spacer');

		if (scrollTop > 0) {
			$siteHeader.removeClass('top');
			$siteHeaderSpacer.removeClass('top');
		} else {
			$siteHeader.addClass('top');
			$siteHeaderSpacer.addClass('top');
		}
	});
}

// Load the scroll state

function mainNavigation() {
	mainNavigationScroll();
	const $siteHeader = $('.site-header');
	$('#mobile-menu-collapse').on('hide.bs.collapse', function () {
		$siteHeader.removeClass('open');
	});
	$('#mobile-menu-collapse').on('show.bs.collapse', function () {
		$siteHeader.addClass('open');
	});
	const $dropdown = $('.navbar-nav .dropdown');
	const $dropdownToggle = $('.navbar-nav .dropdown-toggle');
	const $dropdownMenu = $('.navbar-nav .dropdown-menu');
	const showClass = 'show'; // Should match the media query for the responsive navbar

	const isMobile = function isMobile() {
		const mediaQuery = window.matchMedia('(min-width: 992px)');
		return !mediaQuery.matches;
	};

	$(window).on('load resize', function () {
		$('#mobile-menu-collapse').collapse('hide');
		$dropdown.hover(
			function () {
				if (isMobile()) return;
				const $this = $(this);
				$this.addClass(showClass);
				$this.find($dropdownToggle).attr('aria-expanded', 'true');
				$this.find($dropdownMenu).addClass(showClass);
			},
			function () {
				if (isMobile()) return;
				const $this = $(this);
				$this.removeClass(showClass);
				$this.find($dropdownToggle).attr('aria-expanded', 'false');
				$this.find($dropdownMenu).removeClass(showClass);
			}
		);
	});
}
// function anchorSmoothScrolling() {
// 	const spacing = 86;
// 	$('a[href*=\\#]:not([href=\\#])').click(function () {
// 		if (
// 			location.pathname.replace(/^\//, '') ===
// 				this.pathname.replace(/^\//, '') ||
// 			location.hostname === this.hostname
// 		) {
// 			let target = $(this.hash);
// 			target = target.length
// 				? target
// 				: $('[name=' + this.hash.slice(1) + ']');
// 			if (target.length) {
// 				$('html,body').animate(
// 					{
// 						scrollTop: target.offset().top - spacing,
// 					},
// 					1000
// 				);
// 				return false;
// 			}
// 		}
// 	});
//
// 	if (window.location.hash) {
// 		$('html,body').animate(
// 			{
// 				scrollTop: $(window.location.hash).offset().top - spacing,
// 			},
// 			1000
// 		);
// 	}
// }

/**
 * Use Bootstrap framework styles with Ninja Forms validation.
 */
function ninjaValidation() {
	if (typeof Marionette !== 'undefined') {
		const ninjaValidationController = Marionette.Object.extend({
			initialize() {
				this.listenTo(
					Backbone.Radio.channel('fields'),
					'add:error',
					this.addError
				);

				this.listenTo(
					Backbone.Radio.channel('fields'),
					'remove:error',
					this.removeError
				);
			},

			addError(response) {
				const $el = $('#nf-field-' + response.id);
				$el.addClass('is-invalid');
			},

			removeError(response) {
				const $el = $('#nf-field-' + response.id);
				$el.removeClass('is-invalid');
			},
		});

		// Instantiate our validation controller, defined above.
		new ninjaValidationController();
	}
}

/*******************************************
 *  Custom Functions for the theme
 *******************************************/

/**
 * On Loads
 */
$(document).ready(function () {
	fouc();
	optimizeScroll();
	mainNavigationScroll();
	mainNavigation();
	// anchorSmoothScrolling();
	ninjaValidation();
});
